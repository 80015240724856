import React from "react"
import classNames from "classnames"

const Container = ({
  children,
  mobile,
  tablet,
  desktop,
  fullhd,
  isCentered,
  customClassName,
  margin,
  padding,
}) => {
  return (
    <div
      className={classNames(`columns mx-${margin || 1}-mobile`, {
        "is-centered": isCentered,
      })}
    >
      <div
        className={classNames(
          `column is-${mobile || 12}-mobile is-${tablet || 10}-tablet is-${
            desktop || 8
          }-desktop is-${fullhd || 6}-fullhd p-${padding || 0}  m-${
            margin || 0
          }`,
          { [customClassName]: !!customClassName }
        )}
      >
        {children}
      </div>
    </div>
  )
}

export default Container
