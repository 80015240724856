import React, { Fragment } from "react"
import { navigate, Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons"

import Button from "./Button"

const ActionButtons = ({ submit, next, back }) => (
  <Fragment>
    <div className="columns is-mobile">
      {back && !submit?.label && (
        <div className="column">
          <Button
            onClick={() => {
              if (back.callback) {
                back.callback()
                return
              }
              navigate(back.link)
            }}
            className="mr-1 mr-1-mobile"
          >
            <span className="icon">
              <FontAwesomeIcon icon={faArrowLeft} />
            </span>
            <span className="is-hidden-mobile">{back.label}</span>
            <span className="is-hidden-tablet">Back</span>
          </Button>
        </div>
      )}

      {next && (
        <div className="column has-text-right">
          <Button
            onClick={() => navigate(next.link)}
            className="mr-1 mr-1-mobile"
            color="primary"
            isDisabled={next.disabled}
          >
            <span className="is-hidden-mobile">{next.label}</span>
            <span className="is-hidden-tablet">Next</span>
            <span className="icon">
              <FontAwesomeIcon icon={faArrowRight} />
            </span>
          </Button>
        </div>
      )}
    </div>

    {submit && (
      <center>
        <Button
          className="px-2 px-2-mobile"
          size="medium"
          color="primary"
          type="submit"
          isLoading={submit.loading}
          isDisabled={submit.disabled}
        >
          {submit.label}
        </Button>
      </center>
    )}
    <center className="mt-1">
      {back && submit?.label && (
        <Button
          className="is-text"
          onClick={() => {
            if (back.callback) {
              back.callback()
              return
            } else navigate(back.link)
          }}
        >
          <span className="icon">
            <FontAwesomeIcon icon={faArrowLeft} />
          </span>
          <span>{back?.label}</span>
        </Button>
      )}
    </center>
  </Fragment>
)

export default ActionButtons
