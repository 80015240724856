import React, { useContext } from "react"
import { navigate } from "gatsby"
import { Formik, Form } from "formik"
import { GATSBY_SKIP_FORMS } from "gatsby-env-variables"

import ActionButtons from "../Elements/ActionButtons"
import InvalidFormMessage from "../Elements/InvalidFormMessage"
import EmployeeInformation from "./SelfRequest/PersonalDetails/EmployeeInformation"
import EmergencyContact from "./SelfRequest/PersonalDetails/EmergencyContact"

import { AppContext } from "../../context/AppContext"
import { devSelfRequestInitialValues } from "./utils/formData"
import { getSignedInUser } from "../Auth/services/user"

const SelfRequestForm = ({ nextRoute, location }) => {
  const { state, dispatch } = useContext(AppContext)
  const { selfRequest } = state

  const userData = getSignedInUser()?.userData
  const addresses = getSignedInUser()?.addresses

  const handleSubmit = async () => {
    navigate(nextRoute)
  }

  return (
    <Formik
      initialValues={
        GATSBY_SKIP_FORMS ? devSelfRequestInitialValues : selfRequest
      }
      // validationSchema={selfRequestIntakeValidationSchema}
      onSubmit={handleSubmit}
    >
      {() => (
        <Form key="intakeSelfRequest">
          <EmployeeInformation
            userData={userData}
            addresses={addresses?.addresses}
            location={location}
          />
          <EmergencyContact userData={userData} location={location} />
          <ActionButtons submit={{ label: "Next" }} />
        </Form>
      )}
    </Formik>
  )
}

export default SelfRequestForm
