import React from "react"
import classNames from "classnames"

import Section from "elements/Section"
import EditDetailsButton from "elements/EditDetailsButton"

import requestFields from "./utils/requestFields.json"
import styles from "./utils/request.module.scss"

const EmployeeInformation = ({ userData, addresses, location }) => {
  const primaryAddress = addresses?.find((address) => address?.primary == true)

  return (
    <Section
      id="employee-information"
      title="Employee Information"
      addOns={{
        right: (
          <EditDetailsButton
            route="/profile/edit"
            backRoute={location?.pathname}
          />
        ),
      }}
    >
      <table className="table is-fullwidth">
        <tbody>
          {requestFields["personal"].map((field) => {
            let value = ""
            switch (field.key) {
              case "name":
                value = `${userData?.firstName} ${userData?.lastName}`
                break
              case "birthday":
                value = `${userData?.birthday.month.value || ""} ${
                  userData?.birthday.date.value || ""
                },
                  ${userData?.birthday.year || ""}`
                break
              case "address":
                value = `${primaryAddress?.streetAddress},
                  ${primaryAddress?.city},
                  ${primaryAddress?.province}`
                break
              case "site":
                value = userData?.[field.key] ? userData[field.key]?.label : ""
                break
              default:
                value = userData?.[field.key] ? userData[field.key] : ""
            }

            return (
              <tr>
                <th className={classNames(styles["summary__tableLabel"])}>
                  {field?.label}
                </th>
                <td className={classNames(styles["summary__tableData"])}>
                  {value}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </Section>
  )
}

export default EmployeeInformation
