import * as Yup from "yup"
import moment from "moment"
import { GATSBY_CLIENT_EMAIL_DOMAIN } from "gatsby-env-variables"

const validYear = moment().subtract(18, "years").year()
const REQUIRED_MESSAGE = "This field is required"
const VALID_MOBILE_NUMBER =
  "Please input a valid mobile number in this format: 09991234567"
const VALID_YEAR = "Please input a valid year"
const AGE_LIMIT = "You must be 18 and above"

export const selfRequestIntakeValidationSchema = Yup.object().shape({
  employee: Yup.object().shape({
    firstName: Yup.string().required(REQUIRED_MESSAGE),
    lastName: Yup.string().required(REQUIRED_MESSAGE),
    gender: Yup.string().required(REQUIRED_MESSAGE),
    birthday: Yup.object({
      month: Yup.string().required(REQUIRED_MESSAGE),
      date: Yup.string().required(REQUIRED_MESSAGE),
      year: Yup.number()
        .min(1900, VALID_YEAR)
        .max(validYear, AGE_LIMIT)
        .required(REQUIRED_MESSAGE),
    }),
    site: Yup.string().required(REQUIRED_MESSAGE),
    department: Yup.string().required(REQUIRED_MESSAGE),
    employmentStart: Yup.string().required(REQUIRED_MESSAGE),
    mobileNumber: Yup.string()
      .min(11, VALID_MOBILE_NUMBER)
      .max(11, VALID_MOBILE_NUMBER)
      .matches(/^09[0-9]{9}$/, VALID_MOBILE_NUMBER)
      .required(REQUIRED_MESSAGE),
    employeeNumber: Yup.string().required(REQUIRED_MESSAGE),
    workEmail: Yup.string()
      .matches(
        /^[a-zA-Z.0-9]{1,}[^@]{1}$/,
        `No need to type ${GATSBY_CLIENT_EMAIL_DOMAIN}`
      )
      .required(REQUIRED_MESSAGE),
    personalEmail: Yup.string().email("Please input a valid email"),
    currentWorkHours: Yup.string(),
  }),
  emergencyContact: Yup.object().shape({
    fullName: Yup.string().required(REQUIRED_MESSAGE),
    mobileNumber: Yup.string()
      .min(11, VALID_MOBILE_NUMBER)
      .max(11, VALID_MOBILE_NUMBER)
      .matches(/^09[0-9]{9}$/, VALID_MOBILE_NUMBER)
      .required(REQUIRED_MESSAGE),
    relationshipToPatient: Yup.string().required(REQUIRED_MESSAGE),
  }),
})

export const checklistValidationSchema = Yup.object().shape({
  hasConcern: Yup.string().required(REQUIRED_MESSAGE),
  raisedConcern: Yup.string().when("hasConcern", {
    is: "Yes",
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  causeDistress: Yup.string().required(REQUIRED_MESSAGE),
  seenMHP: Yup.string().required(REQUIRED_MESSAGE),
  seenMedGrocerMHP: Yup.string().required(REQUIRED_MESSAGE),
  medGrocerMHP: Yup.string().when("seenMedGrocerMHP", {
    is: "Yes",
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  hospitalizedForPsychiatricReasons: Yup.string().required(REQUIRED_MESSAGE),
  hasPsychiatricMeds: Yup.string().required(REQUIRED_MESSAGE),
  psychiatricMeds: Yup.string().when("hasPsychiatricMeds", {
    is: "Yes",
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  hasNonPsychiatricMeds: Yup.string().required(REQUIRED_MESSAGE),
  nonPsychiatricMeds: Yup.string().when("hasNonPsychiatricMeds", {
    is: "Yes",
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  hasDrugAllergies: Yup.string().required(REQUIRED_MESSAGE),
  drugAllergies: Yup.string().when("hasDrugAllergies", {
    is: "Yes",
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  beenDiagnosed: Yup.string().required(REQUIRED_MESSAGE),
  pastDiagnoses: Yup.string().when("beenDiagnosed", {
    is: "Yes",
    then: Yup.string().required(
      'If you feel uncomfortable answering this, please choose "I\'d rather not say" instead'
    ),
  }),
  kesslerNervous: Yup.string().required(REQUIRED_MESSAGE),
  kesslerHopeless: Yup.string().required(REQUIRED_MESSAGE),
  kesslerRestlessFidgety: Yup.string().required(REQUIRED_MESSAGE),
  kesslerDepressed: Yup.string().required(REQUIRED_MESSAGE),
  kesslerEffort: Yup.string().required(REQUIRED_MESSAGE),
  kesslerWorthless: Yup.string().required(REQUIRED_MESSAGE),
  wishedToBeDead: Yup.string().required(REQUIRED_MESSAGE),
})

export const cssrsValidationSchema = Yup.object().shape({
  currentSuicidalThoughts: Yup.string().required(REQUIRED_MESSAGE),
  suicidalThoughtsWithMethod: Yup.string().required(REQUIRED_MESSAGE),
  suicidalIntentWithoutSpecificPlan: Yup.string().required(REQUIRED_MESSAGE),
  suicidalIntentWithPlan: Yup.string().required(REQUIRED_MESSAGE),
  suicidalBehavior: Yup.string().required(REQUIRED_MESSAGE),
})

export const referIntakeValidationSchema = Yup.object().shape({
  referrer: Yup.object().shape({
    firstName: Yup.string().required(REQUIRED_MESSAGE),
    lastName: Yup.string().required(REQUIRED_MESSAGE),
    mobileNumber: Yup.string()
      .min(11, VALID_MOBILE_NUMBER)
      .max(11, VALID_MOBILE_NUMBER)
      .matches(/^09[0-9]{9}$/, VALID_MOBILE_NUMBER)
      .required(REQUIRED_MESSAGE),
    email: Yup.string()
      .email("Please input a valid email")
      .required(REQUIRED_MESSAGE),
    currentRole: Yup.string().required(REQUIRED_MESSAGE),
  }),
  employee: Yup.object().shape({
    firstName: Yup.string().required(REQUIRED_MESSAGE),
    lastName: Yup.string().required(REQUIRED_MESSAGE),
    birthday: Yup.object({
      month: Yup.string(),
      date: Yup.string(),
      year: Yup.number().min(1900, VALID_YEAR).max(validYear, AGE_LIMIT),
    }),
    mobileNumber: Yup.string()
      .min(11, VALID_MOBILE_NUMBER)
      .max(11, VALID_MOBILE_NUMBER)
      .matches(/^09[0-9]{9}$/, VALID_MOBILE_NUMBER)
      .required(REQUIRED_MESSAGE),
    personalEmail: Yup.string()
      .email("Please input a valid email")
      .required(REQUIRED_MESSAGE),
    // email: Yup.string()
    //   .matches(
    //     /^[a-zA-Z.0-9]{1,}[^@]{1}$/,
    //     `No need to type ${GATSBY_CLIENT_EMAIL_DOMAIN}`
    //   )
    //   .required(REQUIRED_MESSAGE),
    employeeNumber: Yup.string(),
    site: Yup.string().required(REQUIRED_MESSAGE),
    department: Yup.string().required(REQUIRED_MESSAGE),
    currentWorkHours: Yup.string(),
  }),
  reasonForReferral: Yup.string().required(REQUIRED_MESSAGE),
  riskAssessmentScore: Yup.string(),
})

export const scheduleValidationSchema = Yup.object().shape({
  schedule1: Yup.object().shape({
    day: Yup.string().required(REQUIRED_MESSAGE),
    time: Yup.string().required(
      "Please indicate when you are free at this chosen day"
    ),
  }),
  schedule2: Yup.object().shape({
    day: Yup.string().required(REQUIRED_MESSAGE),
    time: Yup.string().required(
      "Please indicate when you are free at this chosen day"
    ),
  }),
  schedule3: Yup.object().shape({
    day: Yup.string().required(REQUIRED_MESSAGE),
    time: Yup.string().required(
      "Please indicate when you are free at this chosen day"
    ),
  }),
})

export const genderChoices = ["Female", "Male", "Other"]

export const currentRoleChoices = [
  "Yogi or Life Coach",
  "Master Avatar",
  "Human Resources",
  "Team Leader/Manager",
]

export const riskAssessmentScoreChoices = [
  {
    label: "Low",
    value: "Low",
  },
  {
    label: "Moderate",
    value: "Moderate",
  },
  {
    label: "High",
    value: "High",
  },
  {
    label: "Emergency",
    value: "Emergency",
  },
]

export const sites = [
  {
    label: "BOH (Bohol)",
    value: "taskus_boh",
  },
  {
    label: "CHR (Anonas)",
    value: "taskus_chr",
  },
  {
    label: "FE (Bulacan)",
    value: "taskus_fex",
  },
  {
    label: "HTW (Pampanga)",
    value: "taskus_htw",
  },
  {
    label: "LBL (BGC)",
    value: "taskus_lbl",
  },
  {
    label: "LH (La Union)",
    value: "taskus_lh",
  },
  {
    label: "LL (Batangas)",
    value: "taskus_lzl",
  },
  {
    label: "LN (Cavite)",
    value: "taskus_lzn",
  },
  {
    label: "LWT (Antipolo)",
    value: "taskus_lwt",
  },
]

export const daysOfTheWeek = [
  {
    label: "Mon",
    value: "Mon",
  },
  {
    label: "Tue",
    value: "Tue",
  },
  {
    label: "Wed",
    value: "Wed",
  },
  {
    label: "Thu",
    value: "Thu",
  },
  {
    label: "Fri",
    value: "Fri",
  },
  {
    label: "Sat",
    value: "Sat",
  },
  {
    label: "Sun",
    value: "Sun",
  },
]

export const timeBlocks = [
  {
    label: "6AM-9AM",
    value: "6AM-9AM",
  },
  {
    label: "9AM-12NN",
    value: "9AM-12NN",
  },
  {
    label: "12NN-3PM",
    value: "12NN-3PM",
  },
  {
    label: "3PM-6PM",
    value: "3PM-6PM",
  },
  {
    label: "6PM-9PM",
    value: "6PM-9PM",
  },
  {
    label: "9PM-12MN",
    value: "9PM-12MN",
  },
  {
    label: "12MN-3AM",
    value: "12MN-3AM",
  },
  {
    label: "3AM-6AM",
    value: "3AM-6AM",
  },
]

export const devSelfRequestInitialValues = {
  agreeToConsent: ["I agree to the terms and conditions and privacy policy"],
  employee: {
    firstName: "Juan",
    lastName: "dela Cruz",
    gender: "Male",
    birthday: {
      month: { label: "Jan", value: "Jan" },
      date: { label: 1, value: 1 },
      year: 2020,
    },
    site: {
      label: "FE (Bulacan)",
      value: "FE (Bulacan)",
    },
    department: "IT",
    employmentStart: "Jan 2020",
    mobileNumber: "09991234567",
    workEmail: "medgrocer",
    employeeNumber: "123123",
    personalEmail: "mcgborreo@gmail.com",
  },
  emergencyContact: {
    fullName: "Maria dela Cruz",
    mobileNumber: "09992234567",
    relationshipToPatient: "Mother",
  },
  hasConcern: "",
  raisedConcern: "",
  causeDistress: "",
  seenMHP: "",
  seenMedGrocerMHP: "",
  medGrocerMHP: "",
  hospitalizedForPsychiatricReasons: "",
  psychiatricMedicines: "Quetiapine",
  currentNonPsychiatricMedicines: "Losartan",
  drugAllergies: "Ibuprofen",
  beenDiagnosed: "",
  pastDiagnoses: "",
  notes: "Notes here",
  schedule: [
    {
      day: "",
      time: "",
    },
    {
      day: "",
      time: "",
    },
    {
      day: "",
      time: "",
    },
  ],
  usedGoogleMeet: ["Google Meet"],
}

export const devReferInitialValues = {
  agreeToConsent: [],
  referrer: {
    firstName: "Jose",
    lastName: "Santos",
    mobileNumber: "09997891234",
    workEmail: "medgrocer",
    currentRole: "Human Resources",
  },
  employee: {
    firstName: "Juan",
    lastName: "dela Cruz",
    workEmail: "medgrocer",
    employeeNumber: "123123",
    personalEmail: "mcgborreo@gmail.com",
    mobileNumber: "09991234567",
    site: {
      label: "FE (Bulacan)",
      value: "FE (Bulacan)",
    },
    department: "IT",
  },
  reasonForReferral: "Put reason for referral here",
  riskAssessmentScore: {
    label: "Low",
    value: "Low",
  },
}
