import React from "react"
import Layout from "../../components/Layout/Layout"
import Container from "../../components/Layout/Container"
import SelfRequestForm from "../../components/Intake/SelfRequestForm"

export default ({ location }) => (
  <Layout
    title="Get Started"
    subtitle="Fill out this intake form so we can set you up with a mental health professional"
    seoTitle="Personal Details (Self-request Teleconsult)"
    display={{ footer: false, helpCenterBanner: false }}
    isPrivate
  >
    <Container isCentered desktop={6} fullhd={6}>
      <SelfRequestForm nextRoute="/intake/self/history" location={location} />
    </Container>
  </Layout>
)
