import React from "react"
import classNames from "classnames"

import Section from "elements/Section"
import EditDetailsButton from "elements/EditDetailsButton"

import requestFields from "./utils/requestFields.json"
import styles from "./utils/request.module.scss"

const EmergencyContact = ({ userData, location }) => (
  <Section
    title="Emergency Contact Information"
    id="emergency-contact-person"
    addOns={{
      right: (
        <EditDetailsButton
          route="/profile/edit"
          backRoute={location?.pathname}
        />
      ),
    }}
  >
    <table className="table is-fullwidth">
      <tbody>
        {requestFields["emergency"].map((field) => {
          let value = ""
          switch (field.key) {
            case "relationship":
              value =
                userData?.emergencyContact[field.key]?.label ||
                userData?.emergencyContact[field.key]
              break
            default:
              value = userData?.emergencyContact[field.key]
          }

          return (
            <tr>
              <th className={classNames(styles["summary__tableLabel"])}>
                {field.label}
              </th>
              <td className={classNames(styles["summary__tableData"])}>
                {value}
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  </Section>
)

export default EmergencyContact
