import React from "react"

import invalidFormMessages from "./utils/invalidFormMessages.json"

const InvalidFormMessage = ({ children, color, name }) => (
  <article className={`message is-${color}`}>
    <div className="message-body">{children || invalidFormMessages[name]}</div>
  </article>
)

export default InvalidFormMessage
