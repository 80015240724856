import firebase from "firebase"
import { navigate } from "gatsby"
import { hasSignedInUser } from "./user"
import { isBrowser } from "services/general"

export const handleSignOut = ({ redirect = true }) => {
  try {
    firebase.auth().signOut()
    if (isBrowser()) {
      sessionStorage.removeItem("userData")
      sessionStorage.removeItem("addresses")
      sessionStorage.removeItem("contextState")
      sessionStorage.removeItem(hasSignedInUser())
    }
    if (redirect)
      if (window.location.pathname) window.location.reload()
      else return navigate("/sign-in")
  } catch (error) {
    console.error("Sign Out Error", error)
  }
}
