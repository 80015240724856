import React, { useState } from "react"
import moment from "moment"
import classNames from "classnames"
import { useQuery } from "@apollo/react-hooks"
import { gql } from "apollo-boost"

import styles from "./utils/layout.module.scss"
import {
  hasSeenNotification,
  setNotificationAsSeen,
} from "./services/notification"

import {
  GATSBY_WEBSITE_URL,
  GATSBY_ENABLE_RECONFIRMATION_NOTIFICATION,
  GATSBY_HELP_EMAIL,
} from "gatsby-env-variables"

const Notification = () => {
  let dateToday = moment().format("YYYY-MM-DD")
  const [notificationHidden, setNotificationHidden] = useState(false)
  const hasSeenNotificationMessage = hasSeenNotification()

  const { loading, error, data } = useQuery(
    gql`
      query TodaysDate($holiday_date: date!, $domain: jsonb) {
        holiday(
          where: {
            holiday_date: { _eq: $holiday_date }
            domains: { _contains: $domain }
          }
        ) {
          name
          holiday_date
          short_message
        }
      }
    `,
    {
      variables: {
        holiday_date: dateToday,
        domain: GATSBY_WEBSITE_URL,
      },
    }
  )

  if (loading) return null
  if (error) return null

  let notificationMessage =
    data.holiday === undefined || data.holiday.length === 0
      ? null
      : data.holiday[0].short_message
  if (GATSBY_ENABLE_RECONFIRMATION_NOTIFICATION)
    notificationMessage = (
      <span>
        All slots are tentative. Please confirm your slot by emailing{" "}
        <a href={`mailto:${GATSBY_HELP_EMAIL}`} className="has-text-primary">
          {GATSBY_HELP_EMAIL}
        </a>{" "}
        24 to 48 hours before your session.
      </span>
    )
  if (notificationMessage && !hasSeenNotificationMessage)
    return (
      <div
        className={classNames(
          "notification is-info",
          {
            "is-hidden": notificationHidden,
          },
          styles["notification"]
        )}
      >
        <button
          className="delete"
          onClick={() => {
            setNotificationHidden(true)
            setNotificationAsSeen()
          }}
        ></button>
        <div className="has-text-centered">{notificationMessage}</div>
      </div>
    )

  return null
}

export default Notification
